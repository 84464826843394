<template>
  <div class="form-container">
    <v-form
      v-if="!isSuccess"
      ref="form"
      lazy-validation
      class="d-flex flex-column py-15 mx-5"
      @submit="onSubmit()"
      @submit.prevent
    >
      <label for="newPassword" class="custom-input-label">{{ $t("common.pleaseEnterNewPass") }}</label>
      <v-text-field
        id="newPassword"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword ? 'text' : 'password'"
        v-model="password"
        :rules="rules.password"
        @keydown.space.prevent
        outlined
        solo
        flat
        required
        @click:append="showPassword = !showPassword"
      />

      <label for="newPasswordConfirmation" class="custom-input-label">{{ $t("common.pleaseConfirmNewPass") }}</label>
      <v-text-field
        id="newPasswordConfirmation"
        :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPasswordConfirm ? 'text' : 'password'"
        :rules="rules.passwordConfirm"
        outlined
        solo
        flat
        required
        @click:append="showPasswordConfirm = !showPasswordConfirm"
      />

      <v-btn
        elevation="0"
        class="btn-icon shadow-white mt-5"
        block
        color="btn-grad--orange"
        rounded
        x-large
        @click.stop="onSubmit"
      >
        <span class="text-center w-100">{{ $t("buttons.next") }}</span>
        <v-icon color="var(--color__white)">mdi-chevron-right-circle-outline</v-icon>
      </v-btn>
    </v-form>
    <div v-else class="d-flex flex-column mx-5 py-15 my-10">
      <div class="shadow-smoke bg-white py-15 py-md-5 text-center">
        <span>{{$t('common.passSettingComplete')}}</span>
      </div>
      <v-btn
        elevation="0"
        class="btn-icon shadow-white mt-10"
        block
        color="btn-grad--orange"
        rounded
        x-large
        to="/login"
      >
        <span class="text-center w-100">{{ $t("buttons.loginSecond") }}</span>
        <v-icon color="var(--color__white)">mdi-chevron-right-circle-outline</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import {
  checkPassword
} from '@/utils/validate'

export default {
  name: 'PasswordReset',
  data () {
    return {
      password: '',
      token: this.$route.query.token,
      code: this.$route.query.code,
      isSuccess: false,
      rules: {
        password: [
          v => !!v || this.$t('rules.passwordIsRequired'),
          v => checkPassword(v) || this.$t('rules.passwordRules')
        ],
        passwordConfirm: [
          v => v === this.password || this.$t('rules.passwordNotMatch')
        ]
      },
      showPassword: false,
      showPasswordConfirm: false
    }
  },
  methods: {
    async onSubmit () {
      if (this.$refs.form.validate()) {
        await this.$doLoading(async () => {
          await this.$showGqlError(async () => {
            await this.$store.dispatch('confirmPasswordReset', {
              apollo: this.$apollo,
              code: this.code,
              token: this.token,
              password: this.password
            })
            this.isSuccess = true
          })
        })
      }
    }
  }
}
</script>
